.ui.vertical.sidebar.menu {
  > .item {
    border-top: solid 1px #839773;
  }
}

.ui.top.sidebar.mobilemenu {
  background-color: #ffffff;
}

.sidebar .servicelink {
  border-top: 1px solid #839773;

  a {
    color: #ffffff;
  }
}
